@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@400;500;600;700&display=swap");

@layer base {
  html,
  body {
    font-family: "Archivo Narrow", sans-serif;
  }
}
